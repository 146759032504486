
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <b-button class="btn btn-danger float-right" v-b-modal.add-user>Add new</b-button>
      </div>
    </div>

    <br/>

    <b-table striped hover :items="users" :fields="columns">
      <template v-slot:cell(role)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>
      <template v-slot:cell(ranks)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value.name }}</span>

      </template>
      <template v-slot:cell(username)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>
      <template v-slot:cell(location)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value.locationName }}</span>

      </template>
      <template v-slot:cell(target)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value | currency("KES. ", 0) }}</span>

      </template>
      <template v-slot:cell(status)="data">

        <span class="badge badge-success" v-if="data.item.status == 'ACTIVE'">Active</span>
        <span class="badge badge-danger" v-else>Inactive</span>

      </template>
      <template v-slot:cell(phoneNumber)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <span>{{ data.value }}</span>

      </template>

      <template v-slot:cell(options)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-dropdown text="Action">
          <b-dropdown-item href="#" @click="openEditUserModal(data.item)">Edit</b-dropdown-item>
          <b-dropdown-item href="#" @click="openResetPassword(data.item)">Reset Password</b-dropdown-item>
        </b-dropdown>

      </template>

    </b-table>

    <b-modal id="add-user" title="Add User" @cancel="true" @ok="validateBeforeSubmitNewUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.username" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Role:</label> <br/>
                <div >
                  <select class="form-control" v-model="user.role">
                    <option :value="role.role" v-for="role in roles"> {{ role.role }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Password:</label> <br/>
                <div >
                  <input class="form-control" type="password"  v-model="user.password" />
                </div>
              </div>
              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Location:</label> <br/>
                    <div >
                      <select class="form-control" v-model="user.locationId">
                        <option value="0" > All </option>
                        <option :value="location.id" v-for="location in locations">{{ location.locationName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Monthly Target:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.target" />
                </div>
              </div>

            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal id="edit-user" title="Edit User" @cancel="true" @ok="validateBeforeSubmitEditUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.username" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Role:</label> <br/>
                <div >
                  <select class="form-control" v-model="user.role">
                    <option :value="role.role" v-for="role in roles"> {{ role.role }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Status:</label> <br/>
                <div >
                  <label for="status_active">
                    <input  id="status_active" value="ACTIVE" type="radio"  v-model="user.status" /> &nbsp;&nbsp; Active
                  </label> <br/>
                  <label for="status_inactive">
                    <input  id="status_inactive" value="INACTIVE" type="radio"  v-model="user.status" /> &nbsp;&nbsp; Inactive
                  </label>

                </div>
              </div>
              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Location:</label> <br/>
                    <div >
                      <select class="form-control" v-model="user.locationId">
                        <option value="0" > All </option>
                        <option :value="location.id" v-for="location in locations">{{ location.locationName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Monthly Target:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.target" />
                </div>
              </div>

            </div>
          </div>

        </div>

      </form>
    </b-modal>


  </div>
</template>
<script>
  import {counties} from "@/data/counties";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      counties,
      users:[],
      roles:["Admin","Group Admin","Field Reports","App User"],
      columns:["name","role","username","status","phoneNumber","location","target","options"],
      nps_entities:[],
      ranks:[],
      user:{
        id:0,
        name:"",
        username:"",
        phoneNumber:"",
        role:"",
        password:"",
        status:"",
        locationId:"",
        target:0
      },
      isLoading: false,
      fullPage: true,
      filteredRanks:[],
      locations:[]
    };
  },
  computed: {

  },
  components: {
    Loading
  },
  mounted (){
    this.getUsersData();
    this.getRolesData();
    this.getLocationsData();
  },

  methods: {
    ...mapActions(["addUser","editUser","deleteUser","getUsers","getRoles","forgotPassword","getLocations"]),
    getLocationsData(){
      let self = this;
      this.getLocations()
              .then(function (locations) {

                self.locations = locations;

              })
              .catch(function (error) {



              })
    },
    openResetPassword(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Reset password for '+this.user.name +' "')
              .then(value => {

                if(value){
                  self.confirmResetPassword();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    confirmResetPassword(){
      this.isLoading = true;

      let self = this;


      this.forgotPassword({email: this.user.username}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Password reset email sent.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });


      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },
    getRolesData(){
      let self = this;
      this.getRoles()
              .then((roles) => {
                self.roles = roles;
              })
              .catch(error => {

              })
    },

    openEditUserModal(user){

      this.user = user;

      this.$bvModal.show('edit-user');

    },
    openDeleteUser(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.user.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteUser();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    confirmDeleteUser(){
      this.isLoading = true;

      let self = this;


      this.deleteUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },
    getUsersData(){
      let self = this;
      this.getUsers()
        .then(function (users) {
          self.users = users;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }
        })
    },

    validateBeforeSubmitEditUser(){
      this.isLoading = true;

      let self = this;

      this.editUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },
    validateBeforeSubmitNewUser(){
      this.isLoading = true;

      let self = this;

      this.addUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    }
  }
};
</script>
